import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = ({ id, title, author, date, content, image, style}) => (
  <div className="blog-post" style = {style}>
   
      <div className="blog-post-content">
      <Link to={`/blog/${title}`}> <h2>{title}</h2></Link>
        <p className="blog-author">
          By {author} | {date}
        </p>
        <p className="blog-post-preview">{content}</p>
      </div>

  </div>
);

export default BlogPost;
