import React,  { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BlogDetail = () => {
  const { title } = useParams();
  const [post, setPost] = useState(null);
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`/blog/posts/${title}.json`);
        const postsData = await response.json();
        console.log("postsData", postsData)
        setPost(postsData);

      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };
    fetchPost();
  }, [title]);

  if (!post) return <div>Loading...</div>;


  return (
    <div className="blog-detail">
      <img src={post.image} alt={post.title} className="post-image" />
      <h1>{post.title}</h1>
      <p className="blog-author">
        By {post.author} | {post.date}
      </p>
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
    </div>
  );
};

export default BlogDetail;
