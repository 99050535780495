// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => {
    return (
        <div className = "privacy">

        <h1>Privacy Policy</h1>
        <p>Last updated: November 12, 2024</p>
        
        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
        
        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
        
        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        
        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
            <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
            <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
            <li><strong>Application</strong> refers to Family FunsFeed, the software program provided by the Company.</li>
            <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Jinxed LLC, 6 Tingley Lane, Edison, NJ, 08820.</li>
            <li><strong>Country</strong> refers to: New Jersey, United States</li>
            <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
            <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
            <li><strong>Service</strong> refers to the Application.</li>
            <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company.</li>
            <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
            <li><strong>You</strong> means the individual accessing or using the Service.</li>
        </ul>
        
        <h2>Collecting and Using Your Personal Data</h2>
        <h3>Types of Data Collected</h3>
        
        <h4>Personal Data</h4>
        <p>We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. This information may include, but is not limited to:</p>
        <ul>
            <li>Email address</li>
            <li>Phone number</li>
        </ul>
        
        <h4>Usage Data</h4>
        <p>Usage Data is collected automatically when using the Service. This may include information such as Your Device's IP address, browser type, the pages of our Service that You visit, and other diagnostic data.</p>
        
        <h3>Use of Your Personal Data</h3>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
            <li>To provide and maintain our Service.</li>
            <li>To manage Your Account.</li>
            <li>For the performance of a contract.</li>
            <li>To contact You with updates or information related to the Service.</li>
            <li>To provide news, special offers, and general information.</li>
        </ul>
        
        <h3>Retention of Your Personal Data</h3>
        <p>The Company will retain Your Personal Data only for as long as necessary for the purposes set out in this Privacy Policy.</p>
        
        <h3>Transfer of Your Personal Data</h3>
        <p>Your information may be transferred to computers located outside of Your jurisdiction.</p>
        
        <h3>Delete Your Personal Data</h3>
        <p>You have the right to delete or request assistance in deleting the Personal Data that We have collected about You.</p>
        
        <h3>Disclosure of Your Personal Data</h3>
        <ul>
            <li>For business transactions: Personal Data may be transferred in the event of a business transfer.</li>
            <li>For legal requirements: Personal Data may be disclosed as required by law or in response to legal requests.</li>
        </ul>
        
        <h3>Security of Your Personal Data</h3>
        <p>The security of Your Personal Data is important to Us, but no method of transmission over the Internet is 100% secure.</p>
        
        <h2>Children's Privacy</h2>
        <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13.</p>
        
        <h2>Links to Other Websites</h2>
        <p>Our Service may contain links to other websites that are not operated by Us.</p>
        
        <h2>Changes to this Privacy Policy</h2>
        <p>We may update Our Privacy Policy from time to time. You are advised to review this Privacy Policy periodically for any changes.</p>
        
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <ul>
            <li>By email: <a href="mailto:admin@familyfunsfeed.com">admin@familyfunsfeed.com</a></li>
            <li>By visiting this page on our website: <a href="https://familyfunsfeed.com/">https://familyfunsfeed.com/</a></li>
        </ul>
        
        </div>
            
    );
};

export default Privacy;
