import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import Home from './pages/Home'
import Footer from './components/Footer';
import Header from './components/Navbar';
import './index.css';
import Values from './pages/Values'
import Contact from './pages/Contact'
import Blogs from './pages/Blogs'
import BlogDetail from './pages/BlogDetail';
import Privacy from './components/privacy'
import EULA from './components/eula'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <div className="app-content">
      <Header />
      <div className="main-content">
        <div className="pages">
        <Routes>
            <Route 
              path="/" 
              element={ <Home />} 
            />
            <Route 
              path="/values" 
              element={ <Values />} 
            />
            <Route 
              path="/blogs" 
              element={ <Blogs />} 
            />
            <Route 
              path="/blog/:title" 
              element={<BlogDetail />} 
            />
            <Route 
              path="/contact" 
              element={ <Contact />} 
            />
            <Route 
              path="/privacy" 
              element={ <Privacy />} 
            />
            <Route 
              path="/eula" 
              element={ <EULA />} 
            />
          </Routes>
        </div>
        </div>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;