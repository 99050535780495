import React, { useState } from 'react';

const EmailForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    age: '',
    gender: '',
    phoneNumber: ''
  });
  const [message, setMessage] = useState(''); // State for displaying messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('/api/submit-email', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer pat-na1-9fb64e8c-b29b-4b72-9ae7-dc903f3e98d2',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setMessage('Form submitted successfully!');
        setFormData({
          email: '',
          firstName: '',
          lastName: '',
          age: '',
          gender: '',
          phoneNumber: '',
        });
      } else {
        const result = await response.json();
        setMessage(result.message || 'Failed to submit form.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to submit form.');
    }
  };

  return (
    <div className="email-form-container">
    <h2>Stay Up to Date!</h2>
    <p>{message}</p>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="First Name *"
          required
        />
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Last Name *"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email *"
          required
        />
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Phone Number *"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="number"
          name="age"
          value={formData.age}
          onChange={handleChange}
          placeholder="Age"
        />
        <select
          name="gender"
          value={formData.gender}
          onChange={handleChange}
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="non-binary">Non-binary</option>
          <option value="prefer-not-to-say">Prefer not to say</option>
        </select>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
  

  );
};

export default EmailForm;
