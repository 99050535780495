import React, { useEffect, useState } from 'react';
import BlogPost from '../components/BlogPost';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

const Blogs = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('/blog/posts');
        const postsData = await response.json();
        
        const postsWithDetails = await Promise.all(
          postsData.map(async (post) => {
            const postResponse = await fetch(post.URL);
            const postDetails = await postResponse.json();
            return {
              id: postDetails.id,
              title: postDetails.title,
              author: postDetails.author,
              date: new Date(postDetails.date).toLocaleDateString(),
              content: postDetails.content,
              image: postDetails.image
            };
          })
        );

        const firstPost = postsWithDetails.find(post => post.id === 0);
        const remainingPosts = postsWithDetails
          .filter(post => post.id !== 0)
          .sort((a, b) => new Date(b.date) - new Date(a.date));

        if (firstPost) {
          setPosts([firstPost, ...remainingPosts]);
        } else {
          setPosts(remainingPosts);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    fetchPosts();
  }, []);

  return (
    <div className="blogs-container">
      <div className='blog-left'>
        <h1>Read Our Blogs!</h1>
      </div>
      <div className='blog-right'>
        <TransitionGroup>
        {posts.map((post, index) => (
          <CSSTransition
          classNames="fade">
          <BlogPost
            key={post.id}
            id={post.id}
            title={post.title}
            author={post.author}
            date={post.date}
            content={post.content.replace(/<[^>]*>?/gm, '').substring(0, 150) + '...'}
            image={post.image}

            style={{ 
              transitionDelay: `${index * 0.5}s` 
            }}
          />
          </CSSTransition>
        ))}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default Blogs;
