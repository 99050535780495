import React from 'react';
import ValueCard from '../components/ValueCard';

const Values = () => {
    const values = [
        {
            title: 'Privacy and Security',
            description: 'Your family? Your eyes. FFF was built on this mission statement and strives to uphold it.' 
            + 'We promise to put your privacy and security first.',
            imageUrl: 'secret.png'
        },
        {
            title: 'Personalized Content',
            description: 'Tired of seeing advertisements, products, and content for everyone? We here at FFF give you what means the most to you: what\'s up with your family?',
            imageUrl: 'personal.png'
        },
        {
            title: 'Genuine Connections',
            description: 'Enrich your satisfaction by interacting with those who love and care for you. Let\'s stop focusing on trends or drama and instead see what our familys are up to. ',
            imageUrl: 'connection.png'
        },
        {
            title: 'Family',
            description: 'Whether you love em or hate em, these are the folks who will always be your most supportive fans, so let\'s create a connected family together. ',
            imageUrl: 'community.png'
        }
    ];

    return (
        <div className="values-page-section">
            <div className="values-page-left">
                <h2 className="values-title">What We Value:</h2>
            </div>

            <div className="values-page-right">
                <div className="values-grid">
                {values.map((value, index) => (
                        <ValueCard
                        key={index}
                        title={value.title}
                        description={value.description}
                        link={value.link}
                        imageUrl={value.imageUrl}
                    />
                        ))}
                </div>
      </div>
      
    </div>
    );
};

export default Values;
