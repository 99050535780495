import React , { useEffect } from 'react';
import ContactCard from '../components/ContactCard';

const Contact = () => {
    function adjustCardHeights() {
        const cards = document.querySelectorAll(".contact-card");
        
        let maxHeight = 0;
        cards.forEach(card => {
          card.style.height = 'auto';
          const cardHeight = card.offsetHeight;
          if (cardHeight > maxHeight) {
            maxHeight = cardHeight;
          }
        });
      
        cards.forEach(card => {
          card.style.height = `${maxHeight}px`;
        });
      }
  useEffect(() => {
    adjustCardHeights();
    window.addEventListener('resize', adjustCardHeights);
    return () => {
      window.removeEventListener('resize', adjustCardHeights);
    };
  }, []);
    
    const members = [
        {
            name: 'Morgan Ullery',
            title: 'Co-Founder, CMO, COO',
            description: 'Hi, I\'m Morgan Ullery. With a master\'s degree and years of experience in education, I\'m passionate about fostering strong family relationships and ensuring safety. That\'s why I thought of a social media app to help families deepend their bonds and improve communication. My love for education and family dynamics fuels my commitment to using technology to build genuine connections.',
            image: 'morganpfp.png',
          },
          {
          name: 'Keith Yao',
          title: 'Co-Founder, CTO, CFO',
          description: 'Hi! I\'m Keith Yao, a current sophomore at Northeastern University Honors studying Computer Science and Business Administration. My passion for creating strong family '+
          'relationships is fueled by my own childhood memories (the Christmas mornings, Thanksgiving dinners, Chinese New Year celebrations, etc).' + 
          ' Besides working on the user interface delivery, I\'m always in the backend coming up with new ideas and updates for our users! ',
          image: 'keithpfp.png',
        }
      ];
    return (
        <div className="contact-container">
        <h1 className="contact-heading">Meet the Team</h1>
        <div className="cards-container">
            {members.map((member, index) => (
            <ContactCard
            name={member.name}
            title={member.title}
            description={member.description}
            imageUrl={member.image}
        />
            ))}
        </div>
        <h1 className="contact-heading">Contact Us</h1>
        <div className="cards-container">
            <div className="socials-card">
                <div className="socials-icon">
                    <img src="gmail.png" alt="Email" />
                </div>
                <div className="socials-details">
                <a href="mailto:admin@familyfunsfeed.com" target="_blank"><h3>Email</h3></a>
                </div>
            </div>
            <div className="socials-card">
                <div className="socials-icon">
                    <img src="insta.png" alt="Instagram" />
                </div>
                <div className="socials-details">
                <a href="https://www.instagram.com/familyfunsfeed/" target="_blank" rel="noopener noreferrer"><h3>Instagram</h3></a> 
                </div>
            </div>
            <div className="socials-card">
                <div className="socials-icon">
                    <img src="linkedin.png" alt="LinkedIn" />
                </div>
                <div className="socials-details">
                <a href="https://www.linkedin.com/company/family-funsfeed/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><h3>LinkedIn</h3></a>
                </div>
            </div>
            <div className="socials-card">
                <div className="socials-icon">
                    <img src="facebook.png" alt="Facebook" />
                </div>
                <div className="socials-details">
                <a href="https://www.facebook.com/share/ormSZ1Kz3uY2UKie/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><h3>Facebook</h3></a>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Contact;
