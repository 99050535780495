import React from 'react';

const ContactCard = ({ name, title, description, imageUrl}) => {
    return ( 
            <div className="contact-card">
                <div className="contact-card-content">
                    <h3>{name}</h3>
                    <h4>{title}</h4>
                    <img src={imageUrl} alt={title} className="card-image" />
                </div>
                    <p>{description}</p>
            </div>
    );
};

export default ContactCard;
