import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <p>&copy; {new Date().getFullYear()} Jinxed LLC. All rights reserved.</p>
          <p>Email: admin@familyfunsfeed.com</p>
        </div>
        <div className="footer-right">
          <h4>Follow Us!</h4>
          <ul className="social-media">
            <li><a href="https://www.facebook.com/share/18PR3oUGoZ/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li><a href="https://www.instagram.com/familyfunsfeed/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            <li><a href="https://www.linkedin.com/company/family-funsfeed/about/?viewAsMember=true" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
          </ul>
          <h4> <Link to="/privacy">Privacy Policy</Link></h4>
          <h4> <Link to="/eula">End User License Agreement</Link></h4>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
