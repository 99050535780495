import React from 'react';

const ValueCard = ({ title, description, imageUrl, link}) => {
    return ( 
            <div className="value-card">
                <div className="card-content">
                    <h3>{title}</h3>
                    <img src={imageUrl} alt={title} className="card-image" />
                </div>
                <div className="card-hover">
                    <p>{description}</p>
                </div>
            </div>
    );
};

export default ValueCard;
